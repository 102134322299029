.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 50px;
  justify-content: center;
  background-image: url("./assets/banner.jpeg");
  background-position: center;
  background-size: cover;
}

.img-container {
  width: 350px;
  height: 350px;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 30px;
}

.btn {
  margin-top: 50px;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #f1f1f1;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.btn:hover {
  background-color: gray;
}
